<template>
  <b-modal
    ref="modalMotives"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="Insert Motive"
    size="sm"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
  >
    <ValidationObserver ref="form">
      <ValidationProvider
        v-slot="{ errors }"
        name="section-user"
        rules="required"
      >
        <b-form-group label="Insert Motive" label-for="input-1">
          <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
            <template #prepend>
              <div class="input-group-text">
                <b-icon icon="box-seam" class="h4 m-0"> </b-icon>
              </div>
            </template>
            <b-form-input
              id="input-1"
              v-model="motive"
              :state="errors.length > 0 ? false : null"
              trim
            />
          </b-input-group>
        </b-form-group>
      </ValidationProvider>
    </ValidationObserver>
    <template #modal-footer>
      <div style="display: flex; justify-content: end; align-items: center">
        <b-button class="mr-2" @click="closeModal()">Close</b-button>
        <b-button @click="insertMotive()" variant="primary">Save</b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import ParagonSettingsService from "@/views/paragon/views/settings/service/paragon.service.js";
export default {
  props: {},
  data() {
    return {
      motive: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modalMotives");
  },
  created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async insertMotive() {
      const validate = await this.$refs.form.validate();
      if (!validate) return;
      const confirm = await this.showConfirmSwal(
        "Are you sure you want to save this motive?"
      );
      if (!confirm) return;

      try {
        this.addPreloader();
        const params = {
          motive: this.motive,
          user_id: this.currentUser.user_id,
        };
        await ParagonSettingsService.insertMotive(params);
        this.showSuccessSwal("Motive saved successfully");
        this.$emit("inserted");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
        this.closeModal();
      }
    },
  },
};
</script>