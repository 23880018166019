<template>
  <b-modal
    ref="newTitleTicket"
    modal-class="modal-top-0"
    size="lg"
    body-class="body-modal-issue"
    centered
    @hidden="closeModal()"
    title="New Option Support"
    title-class="h3 text-white"
    no-close-on-backdrop
  >
    <b-container>
      <b-form ref="form">
        <ValidationObserver ref="validForm">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group
              id="input-group-title"
              label="Title"
              label-for="input-title"
              description="Short description of the issue"
            >
              <b-form-input
                id="input-title"
                v-model="title"
                type="text"
                class="bg-color-white font-bureau-style border-hover-p"
                :class="{ 'is-invalid': errors[0] }"
                required
              ></b-form-input>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group
              id="input-group-description"
              label="Description"
              label-for="input-description"
              description="Long issue description - Please be as descriptive as possible so our team can reproduce your issue."
            >
              <b-textarea
                id="input-description"
                v-model="description"
                type="text"
                required
                class="bg-color-white font-bureau-style border-hover-p"
                rows="5"
                :class="{ 'is-invalid': errors[0] }"
              ></b-textarea>
            </b-form-group>
          </ValidationProvider>
        </ValidationObserver>
      </b-form>
    </b-container>
    <template #modal-footer>
      <div style="display: flex; justify-content: end; align-items: center">
        <b-button class="mr-2" @click="closeModal()">Cancel</b-button>
        <b-button @click="insertDefaultMessage()" variant="primary"
          >Save</b-button
        >
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex"
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service"
export default {
  props: {
    categoryIssue: {
      type: Number,
      default: null,
    },
    sectionIsuue: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      title: null,
      description: null,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("newTitleTicket")
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
    async insertDefaultMessage() {
      const validate = await this.$refs.validForm.validate()
      if (!validate) return
      const confirm = await this.showConfirmSwal(
        "Are you sure you want to create this new option?",
        "You will not be able to edit it later"
      )
      if (!confirm.value) return
      try {
        this.addPreloader()
        const params = {
          title: this.title,
          description: this.description,
          category: this.categoryIssue,
          section: this.categoryIssue == 1 ? this.sectionIsuue : null,
          user_id: this.currentUser.user_id,
        }
        await helpdeskService.insertMessages(params)
        this.$emit("inserted", null)
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader()
      }
    },
  },
}
</script>