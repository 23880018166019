<template>
  <div class="px-2">
    <b-row>
      <b-col xl="6" lg="12" class="mt-1">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h4 class="font-weight-bolder text-primary">Motives Reject</h4>
          <b-button
            variant="primary"
            class="text-capitalize d-flex align-items-center"
            style="gap: 5px"
            @click="showMotivesModal = true"
          >
            <tabler-icon icon="PlusIcon" />
            New Motive
          </b-button>
        </div>
        <b-table
          ref="ticketMotiveTable"
          slot="table"
          class="blue-scrollbar"
          :items="itemsMotives"
          :fields="motiveFields"
          :busy.sync="isBusyMot"
          primary-key="id"
          responsive="md"
          sticky-header="50vh"
          show-empty
          no-provider-filtering
        >
          <template #cell(created_at)="{ value }">
            {{ value | myGlobalWithHour }}
          </template>
        </b-table>
      </b-col>
      <b-col xl="6" lg="12" class="sup-grid">
        <hr class="hr-media mb-4" />
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h4 class="font-weight-bolder text-warning">Support Options</h4>
          <b-button
            variant="warning"
            class="text-capitalize d-flex align-items-center"
            style="gap: 5px"
            @click="showNewTitle = true"
          >
            <tabler-icon icon="PlusIcon" />
            New Option
          </b-button>
        </div>

        <b-table
          ref="supportTable"
          slot="table"
          class="blue-scrollbar"
          :items="itemsSupport"
          :fields="titleSupportFields"
          :busy.sync="isBusySupp"
          primary-key="id"
          responsive="md"
          sticky-header="50vh"
          show-empty
          no-provider-filtering
        >
          <template #cell(created_at)="{ value }">
            {{ value | myGlobalWithHour }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <NewTitleTicket
      v-if="showNewTitle"
      :categoryIssue="2"
      @close="showNewTitle = false"
      @inserted="
        showNewTitle = false;
        myProviderSupport();
      "
    />
    <MotivesModal
      v-if="showMotivesModal"
      @close="
        showMotivesModal = false;
        myProviderMotives();
      "
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  motivesRejectFields,
  titleSupportFields,
} from "@/views/paragon/views/settings/fields.js";
import NewTitleTicket from "@/views/commons/components/helpdesk/components/NewTitleTicket.vue";
import MotivesModal from "@/views/paragon/views/settings/modals/MotivesModal.vue";
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
import ParagonSettingsService from "@/views/paragon/views/settings/service/paragon.service.js";
export default {
  components: {
    NewTitleTicket,
    MotivesModal,
  },
  data() {
    return {
      motiveFields: motivesRejectFields,
      titleSupportFields: titleSupportFields,
      itemsSupport: [],
      itemsMotives: [],
      isBusySupp: false,
      isBusyMot: false,
      showNewTitle: false,
      showMotivesModal: false,
    };
  },
  props: {
    tabSelected: String,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
  },
  mounted() {
    this.myProviderSupport();
    this.myProviderMotives();
  },
  methods: {
    async myProviderSupport() {
      const params = {
        category: 2,
      };
      const response = await helpdeskService.getDefaultDescription(params);
      this.itemsSupport = response.data;
      return response;
    },
    async myProviderMotives() {
      const response = await ParagonSettingsService.getMotives();
      this.itemsMotives = response;
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.boder-dark {
  border: 0.5px solid #353535;
  margin-bottom: 1rem;
}
.border-light {
  border: 0.5px solid #e4e4e4;
  margin-bottom: 1rem;
}

.sup-grid {
  margin-top: 1rem;
}
.hr-media {
  display: none;
}
// media query 1200px
@media (max-width: 1200px) {
  .hr-media {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .sup-grid {
    margin-top: 3rem;
  }
}
</style>